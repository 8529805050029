@import "../../../mixins";

@mixin mediaMobile {
  @media screen and (max-width: #{rem(700)}) {
    @content;
  }
}

.category-card {
  padding: rem(5) rem(19);
  width: 100%;
  height: auto;
  min-height: rem(104);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: rem(22);

  text-decoration: none;

  background-color: var(--bg-white);
  border: solid 1px var(--stroke-light);
  border-radius: var(--radius-common);

  transition: border-color var(--animation-default);

  @include mediaBigDesktop {
    padding: big(5) big(19);
    min-height: big(104);
    gap: big(22);

    border-width: big(1);
  }

  @include mediaDesktop {
    padding: rem(5) rem(19);
    min-height: rem(88);
    gap: rem(18);
  }

  @include mediaLaptop {
    padding: rem(5) rem(19);
    min-height: rem(84);
    gap: rem(15);
  }

  @include mediaMobile {
    padding: rem(7) rem(11);
    min-height: rem(38);
    gap: rem(10);

    border-radius: var(--radius-tertiary);
  }

  @include hover {
    border-color: var(--accent-primary-default);
  }

  &__image {
    width: rem(44);
    height: rem(44);
    flex-shrink: 0;

    @include mediaBigDesktop {
      width: big(44);
      height: big(44);
    }

    @include mediaDesktop {
      width: rem(32);
      height: rem(32);
    }

    @include mediaLaptop {
      width: rem(24);
      height: rem(24);
    }

    @include mediaMobile {
      width: rem(20);
      height: rem(20);
    }

    & img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;
    }
  }

  &__content {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(3);

    @include mediaBigDesktop {
      gap: big(3);
    }

    @include mediaLaptop {
      gap: rem(2);
    }
  }

  &__title {
    width: 100%;
    display: block;

    color: var(--text-dark-primary);
    font: var(--font-body-L-m);

    @include mediaLaptop {
      font: var(--font-body-M-m);
    }

    @include mediaMobile {
      font: 500 rem(14)/1.55 var(--font-primary);
    }
  }

  &__description {
    width: 100%;
    display: block;

    color: var(--text-dark-secondary);
    font: var(--font-body-S-r);

    @include mediaMobile {
      display: none;
    }
  }
}
